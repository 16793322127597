<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-card class="py-5">
      <v-card-title>
        {{ $t('ressaller.addResaller') }}
      </v-card-title>
      <template>
        <v-form
          v-model="valid"
          @submit.prevent="submitForm()"
        >
          <v-container fluid>
            <v-row class="mx-md-6 px-md-6">
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-if="itemDetails"
                  v-model="itemDetails.branch.name"
                  required
                  :label="$t('filter.branch')"
                  disabled
                  dense
                  outlined
                />
                <v-select
                  v-else
                  v-model="data.branch_id"
                  :items="branches"
                  item-text="name"
                  item-value="id"
                  dense
                  :label="$t('filter.branch')"
                  outlined
                  required
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-if="itemDetails"
                  v-model="itemDetails.label"
                  required
                  :label="$t('filter.label')"
                  disabled
                  dense
                  outlined
                />
                <v-autocomplete
                  v-else
                  v-model="data.product_id"
                  :items="products"
                  :loading="productLoading"
                  :search-input.sync="productSearch"
                  hide-no-data
                  hide-selected
                  outlined
                  dense
                  item-text="label"
                  item-value="id"
                  :label="$t('filter.label')"
                  :placeholder="$t('filter.startTypingToSearch')"
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-autocomplete
                  v-model="data.client_id"
                  :items="items"
                  :loading="isLoading"
                  :search-input.sync="search"
                  hide-no-data
                  hide-selected
                  outlined
                  dense
                  item-text="name"
                  item-value="id"
                  :label="$t('filter.clients')"
                  :placeholder="$t('filter.startTypingToSearch')"
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="data.notes"
                  outlined
                  name="input-7-4"
                  label="Note"
                />
              </v-col>
            </v-row>
            <v-btn
              type="submit"
              class="mx-auto my-auto d-flex"
              color="indigo"
              :loading="loading"
              :disabled="disabled"
            >
              {{ this.$route.params.id ? $t('actions.save') : $t('actions.add') }}
            </v-btn>
          </v-container>
        </v-form>
      </template>
    </v-card>
    <v-snackbar
      v-model="successSnackbar"
      color="success"
      shaped
      absolute
      bottom
      right
      :timeout="timeout"
    >
      {{ successMessage }}
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbar"
      color="red"
      shaped
      absolute
      bottom
      right
      :timeout="timeout"
    >
      {{ errorMessage }}
    </v-snackbar>
  </v-container>
</template>
<script>
  import { ServiceFactory } from '@/services/ServiceFactory'
  const resellerService = ServiceFactory.get('reseller')
  const branchesService = ServiceFactory.get('branches')
  const clientsService = ServiceFactory.get('clients')
  const mainStockServices = ServiceFactory.get('mainStock')

  export default {
    name: 'Companies',
    // eslint-disable-next-line vue/require-prop-types
    props: ['itemDetails'],
    data: (vm) => ({
      descriptionLimit: 30,
      search: null,
      productSearch: null,
      isLoading: false,
      productLoading: false,
      entries: [],
      model: null,
      dataLoading: false,
      valid: false,
      data: {
        branch_id: null,
        client_id: null,
        notes: '',
        product_id: null,
      },
      products: [],
      branches: [],
      successSnackbar: false,
      errorSnackbar: false,
      timeout: 3000,
      successMessage: '',
      errorMessage: '',
      loading: false,
      disabled: false,
    }),
    computed: {
      items () {
        return this.entries.map(entry => {
          const name = entry.name.length > this.descriptionLimit
            ? entry.name.slice(0, this.descriptionLimit) + '...'
            : entry.name
          return Object.assign({}, entry, { name })
        })
      },
      productItems () {
        return this.products.map(product => {
          const name = product.name.length > this.descriptionLimit
            ? product.name.slice(0, this.descriptionLimit) + '...'
            : product.name
          return Object.assign({}, product, { name })
        })
      },
    },

    watch: {
      async search (val) {
        // Items have already been loaded
        if (this.items.length > 0) return

        // Items have already been requested
        if (this.isLoading) return

        this.isLoading = true

        const listData = await clientsService.getActiveList()
        this.entries = listData
        this.count = listData.length
        this.isLoading = false
      },
      async productSearch (val) {
        // Items have already been loaded
        if (this.productItems.length > 0) return

        // Items have already been requested
        if (this.productLoading) return

        this.productLoading = true

        const listData = await mainStockServices.getAllItems('all', 1)
        this.products = listData.data
        this.productCount = listData.data.length
        this.productLoading = false
      },
    },
    created () {
      this.getAllBranches()
    },
    methods: {
      async  submitForm () {
        this.loading = true
        this.disabled = true
        console.log('this.itemDetails', this.itemDetails)
        const dataForm = this.itemDetails ? {
          product_id: this.itemDetails.id,
          branch_id: this.itemDetails.branch.id,
          client_id: this.data.client_id,
          notes: this.data.notes,
        } : this.data
        this.newItem(dataForm)
      },
      async newItem (data) {
        const item = await resellerService.AddNewItem(data)
        try {
          if (item.status === 'success') {
            this.successMessage = 'Resseller Added Successfuly'
            this.successSnackbar = true
          } else {
            this.errorMessage('something Error')
            this.errorSnackbar = true
          }
        } catch (err) {
          console.log('err', err)
          this.errorMessage('something Error')
          this.errorSnackbar = true
        }
        this.disabled = false
        this.loading = false
      },
      async getAllBranches () {
        this.dataLoading = true
        const items = await branchesService.getActiveList()
        console.log('citieas', items)
        this.branches = items.data
        this.dataLoading = false
      },
    },
  }
</script>
<style>
a{
  text-decoration: none;
}
</style>
